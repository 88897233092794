import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

type MerchPageProps = {
  location: Location
}

const MerchPage: React.FC<MerchPageProps> = ({ location }) => {
  return (
    <Layout location={location} title="Grease">
      <h1>Merchandise</h1>
      <p style={{ marginTop: "20px", fontSize: "1.2rem", color: "#555" }}>
        Our merchandise section is coming soon! Stay tuned for awesome products.
      </p>

      <div style={{ marginTop: "30px", textAlign: "center" }}>
        <StaticImage
          src="../images/merch/girl.png"
          alt="Merch girl"
          placeholder="blurred"
          layout="constrained"
          width={400}
          style={{ borderRadius: "10px" }}
        />
      </div>
    </Layout>
  )
}

export default MerchPage
